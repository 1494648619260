$font-family-base: sofia-pro, sans-serif;
$font-family-base-medium: sofia-pro, sans-serif;
$font-family-base-bold: sofia-pro, sans-serif;
$font-family-secondary: sofia-pro, sans-serif;
$font-family-secondary-medium: sofia-pro, sans-serif;

/****** FONT WEIGHT CONFIG *******/
/* Remember to include the corresponding weights from the font family */
$font-weight-light: 300;
$font-weight-base: 400;
$font-weight-secondary: 500;
$font-weight-bold: 700;
$font-weights: (
    light: $font-weight-light,
    base: $font-weight-base,
    secondary: $font-weight-secondary,
    bold: $font-weight-bold,
);

$font-size-mobile-menu: 1.2rem;
$font-size-tiny: 1.1rem;
$font-size-x-small: 1.2rem;
$font-size-small: 1.4rem;
$font-size-sub: 1.5rem;
$font-size-base: 1.6rem;
$font-size-navigation: 2rem;
$font-size-medium: 1.8rem;
$font-size-large: 3rem;
$font-size-large-mobile: 2.4rem;
$font-size-larger: 3.5rem;
$font-size-largest-mobile: 3.8rem;
$font-size-largest: 3.5rem;
$font-size-huge: 6rem;

$line-height-tiny: 1.1;
$line-height-small: 1.3;
$line-height-secondary: 1.5;
$line-height-normal: 1.5;
$line-height-base: 1.81;
$line-height-large: 2;

$letter-spacing-base: normal;
$letter-spacing-x-small: normal;
$letter-spacing-small: normal;
$letter-spacing-medium: normal;
$letter-spacing-large: normal;

$primary-heading-weight-key: 300;

$secondary-heading-weight: 300;
$tertiary-heading-weight: 300;
$tertiary-heading-mobile-size: largeMobile;
$tertiary-heading-mobile-line-height: small;
$tertiary-heading-line-key: tiny;
$tertiary-heading-mobile-weight: 300;

$tiny-heading-line-key: base;
$tiny-heading-weight-key: bold;

$body-copy-bold-weight: 700;
