$footer-background-color: $white;
$footer-divider: $grey-light;
$footer-border: 1px solid $footer-divider;
$footer-navbar-item: $black-bis;
$footer-navbar-item-hover: $green-dark;
$footer-icon-color: $grey-dark;
$footer-label-color: $grey-dark;
$footer-label-color-touch: $black;

ncg-footer {
    .footer {
        background-color: $footer-background-color;
        @include border('bottom', 0.4rem, $primary);

        &-top {
            padding-top: 4.6rem;
            border-top: $footer-border;

            @include until($desktop) {
                padding-top: 1rem;
            }

            &__columns {
                @include until($desktop) {
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
            }

            &__column {
                @include until($desktop) {
                    padding-bottom: 1.5rem;
                    padding-left: 0;
                    padding-right: 0;
                    border-bottom: $footer-border;
                }

                @include from($desktop) {
                    .expansion-panel {
                        &__content {
                            height: auto !important;
                        }
                    }
                }

                &--empty {
                    @include touch {
                        min-height: 6.7rem;
                        display: flex;
                        align-items: center;
                    }
                }
            }

            &__menu-label {
                flex: 1 0 auto;
                font-family: map-get($font-families, bold);
                font-size: map-get($font-sizes, tiny);
                text-transform: uppercase;
                line-height: 1;

                @include from($desktop) {
                    padding-bottom: 1.1rem;
                }

                a {
                    color: $footer-label-color;
                    text-decoration: none;
                    font-weight: $font-weight-bold;

                    &:hover {
                        color: $footer-navbar-item-hover;
                    }

                    @include touch {
                        color: $footer-label-color-touch;
                    }
                }
            }

            &__menu-list {
                a {
                    font-size: map-get($font-sizes, sub);
                    padding: 0;
                    padding: 1rem 0;
                    line-height: 1.2;
                    color: $footer-navbar-item;
                    text-decoration: none;

                    &:hover {
                        color: $footer-navbar-item-hover;
                    }

                    @include desktop {
                        font-size: map-get($font-sizes, navigation);
                    }
                }
            }
        }

        &-bottom {
            @include until($desktop) {
                flex-direction: column;
                padding-top: 0;

                > .container {
                    flex-direction: column;
                }
            }

            @include from($desktop) {
                padding-top: 0;
            }

            &__logo {
                @include until($desktop) {
                    order: 1;
                    align-self: center;
                    padding-bottom: 2rem;
                }

                @include from($desktop) {
                    padding-right: 3rem;
                }
            }

            &__links {
                flex: 1 0 auto;
                padding-top: 0.6rem;

                @include until($desktop) {
                    order: 3;
                }
            }

            &__navbar {
                .navbar-item {
                    color: $grey-dark;
                    font-size: map-get($font-sizes, small);
                    font-weight: map-get($font-weights, base);
                }

                @include until($desktop) {
                    display: flex;
                    flex-flow: wrap row;
                    align-items: center;
                    white-space: nowrap;
                    justify-content: center;
                }
            }

            &__item {
                &:hover {
                    color: lighten($grey-dark, 50%);
                }

                &--divider {
                    &::after {
                        content: '';
                        position: absolute;
                        right: 0;
                        height: 1.4rem;
                        width: 1px;
                        background-color: $footer-divider;
                    }

                    &:last-child {
                        &::after {
                            content: none;
                        }
                    }
                }
            }

            &__social {
                @include until($desktop) {
                    order: 2;
                    padding-bottom: 2.5rem;
                }

                .footer-bottom {
                    &__item {
                        color: $footer-icon-color;
                        opacity: 1;

                        @include until($desktop) {
                            margin: 0 1.5rem;
                            width: 3.4rem;
                        }

                        @include from($desktop) {
                            padding: 0.5rem 2rem;
                        }

                        &:hover {
                            opacity: 0.5;
                        }
                    }
                }
            }

            &__copyright {
                font-size: map-get($font-sizes, tiny);
                padding-left: 1rem;
                color: $grey-dark;

                @include until($desktop) {
                    padding-top: 2rem;
                    text-align: center;
                }
            }
        }
    }
}
