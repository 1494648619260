/****** COLORS *******/
$white: white;
$blue: #1a65c9;
$green: #bece33;
$green-dark: #a4b31d;

$grey-darker: #767676;
$grey-dark: #8e8e8e;
$grey: #e1e1e1;
$grey-light: #e6e6e6;
$grey-lighter: #f6f6f6;
$blue-dark: #215397;

$black: black;
$black-bis: #0d0d0d;
$black-ter: #262626;
$brownish-grey: $grey-dark;

/****** General colors *******/
$foreground: $white;
$background: $white;

$hover: darken($green, 10%);
$primary: $green;
$text: $black;
$text-light: $grey-dark;
