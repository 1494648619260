.mobile-menu {
    &__title {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        line-height: 1.1;
        font-size: map-get($font-sizes, tiny);
    }

    // This should match the value from the mobile-menu-trigger directive
    nav {
        height: calc(100% - 74px);
    }

    &__nav-icon {
        stroke: $black;
    }
}
