$header-mobile-btn-color: $black;
$header-border: $border;
$header-bg-color: $background;
$header-padding-horizontal: 4rem;
$header-logo-x-small-padding-left: 1rem;
$header-logo-small-padding-left: 1.7rem;
$header-logo-large-padding-left: $gutter;
$header-logo-padding-right: 1rem;

ncg-header {
    position: relative;
}

.header {
    position: sticky;
    top: 0;
    z-index: $index-header;
    background-color: $header-bg-color;
    box-sizing: content-box;
    margin-bottom: 2.4rem;
    @include border('top', 0.5rem, $primary);
    @extend .border-bottom;

    &__backdrop {
        &::before {
            content: '';
            position: fixed;
            background-color: $black;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: $index-body-overlay;
            pointer-events: none;
            opacity: 0;
            transition: opacity $duration-fast ease-in-out;
        }

        &--is-open {
            &::before {
                opacity: 0.3;
            }
        }
    }

    &--animate {
        transition: transform $image-animation-duration-fast $smooth-in;
    }

    &--is-sticky {
        position: fixed;
        top: 0;
        transition: transform $image-animation-duration-fast $smooth-out;
        width: 100%;
    }

    &__container {
        margin: 0 auto;

        > .columns {
            padding-left: 0;
            padding-right: 0;
            height: $header-height-mobile;
            padding-top: 0.4rem;

            @include desktop {
                height: $header-height-desktop;
            }
        }
    }

    &__mobile-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5.9rem !important;
    }

    &__mobile-button {
        margin-bottom: 0.6rem !important;
        color: $header-mobile-btn-color;

        &:active,
        &:focus,
        &:hover {
            color: $header-mobile-btn-color;
        }
    }
}

.logo {
    display: flex;

    > img {
        max-width: 90%;

        @include from($mobile-large) {
            padding-left: $header-logo-small-padding-left;
            max-width: 80%;
        }

        @include from($desktop) {
            padding-left: $header-logo-large-padding-left;
            max-width: 12rem;
        }

        @include from($fullhd) {
            max-width: 14rem;
        }

        @include from($fullwidth) {
            max-width: none;
        }
    }
}
