$menu-padding-left: 3.8rem;
$menu-hover-cover-height: 3.7rem;
$menu-hover-cover-top-horizontal: -0.7rem;
$menu-hover-cover-bottom-horizontal: -1rem;

.main-menu {
    &.navbar {
        position: static;

        .main-menu {
            &__item {
                font-family: map-get($font-families, base);
                font-size: map-get($font-sizes, base);
                padding-top: 1.4rem;
                padding-bottom: 1.4rem;

                @include widescreen {
                    font-size: map-get($font-sizes, medium);
                }

                @include from($fullwidth) {
                    font-size: map-get($font-sizes, navigation);
                }

                &:hover {
                    z-index: 2;

                    &::before,
                    &::after,
                    span::before,
                    span::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        width: 5rem;
                    }

                    &::before {
                        top: 50%;
                        left: $menu-hover-cover-top-horizontal;
                    }

                    &::after {
                        top: $menu-hover-cover-height;
                        left: $menu-hover-cover-bottom-horizontal;
                    }

                    span {
                        &::before {
                            top: 50%;
                            right: $menu-hover-cover-top-horizontal;
                        }

                        &::after {
                            top: $menu-hover-cover-height;
                            right: $menu-hover-cover-bottom-horizontal;
                        }
                    }
                }
            }
        }
    }
}
